import React, { Component } from 'react'
import Link from 'gatsby-link'
import Header from "../components/Header"
import '../css/404.css'
import { Helmet } from "react-helmet"

class NotFoundPage extends Component {
    render() {
      return (
        <div>
          <Helmet>
            <title>
              Uh oh.
            </title>
          </Helmet>
            <Header />
            <div className="fourcontainer">
            <h1>404 😦</h1>
            <p>It be like that sometimes.</p>
            <Link to="/">Back to Home</Link>
            </div>
        </div>
      )
    }
  }
  
  export default NotFoundPage;


  